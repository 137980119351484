<template>
  <!-- 模板下载新增编辑页面 -->
  <div>
    <Header
      back="返回"
      title="云学院"
      index="首页"
      titleOne="官网管理"
      titleTwo="云学院"
      titleThree="模板下载"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <div>
        <span>封面</span>
        <div class="flex_l" style="margin-top: 15px;">
          <Modelcover ref="modelcover" />
        </div>
      </div>
      <div>
        <el-form
          style="margin-top: 20px;"
          :rules="formRules"
          label-position="top"
          ref="form"
          :model="modelParams"
        >
          <el-form-item label="模板名称" prop="title">
            <el-input v-model="modelParams.title"></el-input>
          </el-form-item>
          <el-form-item label="模板推荐" prop="recommend">
            <el-select style="width: 370px;" v-model="modelParams.recommend" placeholder="请选择是否推荐">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 上传模板文件按钮 -->
      <div class="upload">
        <el-upload
          class="upload-demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请上传模板文件</el-button>
        </el-upload>
        <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
      </div>
      <el-table :data="dataList" style="width: 100%">
        <el-table-column prop="file_name" label="模板名称"></el-table-column>
        <el-table-column prop="update_time" label="更新时间">
          <template slot-scope="scope">{{ scope.row.update_time ? scope.row.update_time : '-' }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="small" class="edit" @click="editBanner(scope.row.id)">查看</el-button>
            <el-button size="small" class="edit" @click="editBanner(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="width: 100%;text-align: center;">
        <el-button type="primary" :loading="btnLoading" class="sure" @click="sure()">确定</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import Modelcover from '../../components/upload/uploadCover.vue';
import Gqburl from '../../components/global.vue'
import axios from 'axios'
export default {
  components: {
    Modelcover,
  },
  data () {
    return {
      btnLoading: false,//提交按钮动画
      infoCover: '',//回显封面图
      gqbpath: Gqburl.userSite,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      modelParams: {
        id: '',
        type: 'model'
      },
      fileList: [],
      dataList: [],
      formRules: {
        title: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        recommend: [
          { required: true, message: '请选择是否推荐模板', trigger: 'blur' },
        ],
      },
    }
  },
  created () {
    if (this.$route.query.id) {
      this.modelParams.id = this.$route.query.id
      this.videoView()
    }
  },
  methods: {
    videoView () {
      this.axios.get('/api/website/view_article', {
        params: {
          id: this.$route.query.id,
          type: 'model'
        }
      }).then((res) => {
        this.modelParams = res.data
        this.infoCover = res.data.cover_url
      })
    },
    sure () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            this.modelParams.cover_url = this.gqbpath + this.infoCover
          } else {
            this.modelParams.cover_url = this.gqbpath + this.$refs.modelcover.fileCover.url
          }
          this.btnLoading = true
          this.axios.post('/api/website/store_article', { ...this.modelParams, type: 'model' }).then((res) => {
            this.btnLoading = false
            this.$message.success(res.message)
            this.$router.go(-1)
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          this.$message.warning('请完善信息')
        }
      })
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },

    httpRequest (param) {
      let avatar = param.file
      const form = new FormData()
      form.append('file', avatar)
      this.upload('/api/website/upload_file', form).then((res) => {
        console.log(res, 788888)
        this.$message.success(res.message)
        this.dataList.push(res)
      })
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res)
          })
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
  }
}
  </script>
  
  <style scoped>
.sure {
  margin: 50px auto;
}

.add_video {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.add_video i {
  display: block;
  font-size: 50px;
}

.add_video span {
  display: block;
  line-height: 16px;
  margin-top: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
}

.add_video p {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 14px;
  color: #909399;
}

.card_box {
  display: flex;
}

.flex_l {
  position: relative;
}

.flex_r {
  position: relative;
  margin-left: 20px;
}

div/deep/.el-upload--picture-card {
  width: 370px !important;
  height: 234px !important;
}

/* div/deep/.el-upload--picture-card{
      position: relative;
    } */

div/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 370px;
  height: 234px;
  position: absolute;
  top: 0;
  z-index: 9;
}

div/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
  top: 0;
}
div/deep/.ql-container {
  min-height: 300px;
}

.upload {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.upload span {
  margin-left: 15px;
  color: #a6abc7;
}
.upload .ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4;
  border: none;
  color: #909399;
}
</style>